$side-panel-min-width: 50px;

.content {
  line-height: 1.5;

  h2 {
    font-size: 30px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.content-block {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 20px;

  .screen-x-small & {
    margin-left: 20px;
    margin-right: 20px;
  }
}

.responsive-paddings {
  padding: 20px;

  .screen-large & {
    padding: 40px;
  }
}

.dx-card.wide-card {
  border-radius: 0;
  margin-left: 0;
  margin-right: 0;
  border-right: 0;
  border-left: 0;
}

.result-red {
  background-color: rgb(197, 25, 25);
}
.result-yellow{
  background-color: #f2f216;
}
.result-green{
  background-color: rgba(0, 160, 0, 0.30);
}

