:root{
    --base-color: #1aa1bd;
    --text-color:rgba(0,0,0,.54);
    --complement-color: #e5634e;
    --menu-container:#363740;
    --menu-text-color: white;
    --border-color: rgba(193, 193, 193, 0.68);
}

html {
    font-family: Roboto,RobotoFallback,"Noto Kufi Arabic",Helvetica,Arial,sans-serif;
}

body {
    margin: 0px;
    background:#f7f7f7;
}

html, body {
    margin: 0px;
    min-height: 100%;
    height: 100%;
}

* {
box-sizing: border-box;
}
a:link{
    color:var(--base-color);
    text-decoration: none;
}
a:visited{
    color:var(--base-color);
}

::-webkit-scrollbar {
    width: 12px !important;
    height: 12px !important;
}

/* Track */
::-webkit-scrollbar-track {
    background:transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgb(179, 179, 179); 
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555; 
}

/* dx-form .dx-checkbox {
    padding-top: 10px;
} */

/* uom button */
.uom.dx-button-has-text .dx-button-content{
	text-align: right;
    padding-right: 0px;
}
.uom .dx-button-text{
	text-transform: none;
}

ibis-toolbar .dx-drawer.dx-drawer-opened .dx-overlay-content.dx-resizable{
    width: 250px !important;
}
ibis-toolbar .dx-drawer .dx-treeview-toggle-item-visibility{
     display: none;
}
ibis-toolbar .dx-drawer.dx-drawer-opened .dx-treeview-toggle-item-visibility{
    display: block;
}

ibis-header .ibis-company{
    min-width: 200px;
    border-right: 1px solid var(--border-color);
    padding: 2px 10px;
    color: var(--text-color);
    display: flex;
    font-size: 16px;
    font-weight: bold;
    justify-content: space-between;
}
ibis-header .ibis-company-arrow{
    padding: 16px 0;
}
ibis-header .ibis-arrow-right{
    padding: 9px 0px;
    color:var(--text-color);
}
ibis-header  .dx-toolbar .dx-toolbar-items-container {
    height: 56px !important;
}

ibis-header .ibis-header-toggle,
ibis-header .ibis-header-toggle dx-button.dx-state-focused,
ibis-header .ibis-header-toggle dx-button.dx-state-hover  {
    background-color: var(--base-color);
}

ibis-header .ibis-header-toggle dx-button {
    margin-left: 12px;
    margin-right: 4px;
    background-color: var(--base-color);
}

ibis-header .ibis-header-toggle dx-button .dx-icon {
    color: white;
}

/** menu */
.menu-container .dx-treeview .dx-treeview-node[aria-level="1"] {
    font-size: 1.1em;
    border-bottom-width: 0px !important;
}

.menu-container .dx-treeview .dx-treeview-item {
    padding: 0px;
}

.menu-container .dx-treeview .dx-treeview-item .ibis-item-parent:not(.ibis-parent-selected){
    color: rgba(230, 230, 230, 0.87);
}

.menu-container .dx-treeview .dx-treeview-item .ibis-item-child,
.menu-container .dx-treeview .dx-treeview-item .ibis-item-parent {
    padding: 10px 0px;
}

dx-drawer:not(.dx-drawer-opened) .menu-container .dx-treeview-node > .dx-treeview-item .ibis-parent-selected {
    background-color: #f7f7f7 !important;
}

.menu-container .dx-treeview .dx-treeview-node-container .dx-treeview-node.dx-state-selected > .dx-treeview-item {
    background-color: #f7f7f7 !important;
}

ibis-menu .dx-treeview .dx-treeview-node.dx-state-selected .dx-item-content.dx-treeview-item-content{
    color: var(--base-color);
}

ibis-menu .dx-treeview .dx-treeview-node.dx-state-selected .dx-item-content.dx-treeview-item-content .dx-icon{
    color: var(--base-color);
}
ibis-menu .dx-treeview-item .dx-treeview-item-content span {
    vertical-align: top;
}
ibis-menu .dx-treeview .dx-scrollable-content>ul>li:first-child{
    background-color: var(--base-color);
}
ibis-menu .dx-treeview .dx-scrollable-content>ul>li:first-child > .dx-item.dx-treeview-item{
    height: 56px;
    font-size: 21px;
}
ibis-menu .dx-treeview .dx-scrollable-content>ul>li:first-child > .dx-item.dx-treeview-item .dx-treeview-item-content span{
    vertical-align: inherit;
} 
ibis-menu .dx-treeview .dx-scrollable-content>ul>li:first-child > .dx-item.dx-treeview-item > div{
    padding: 7px 0;
}
ibis-menu .dx-treeview .dx-scrollable-content>ul>li:first-child .dx-treeview-toggle-item-visibility{
    margin-top: 9px;
}
ibis-menu .dx-treeview-toggle-item-visibility, ibis-menu .dx-item-content.dx-treeview-item-content,
ibis-menu .dx-texteditor-input{
    color:var(--menu-text-color);
}
ibis-menu .dx-treeview .dx-treeview-item.dx-state-hover{
    background-color: #00000038 !important;
}
ibis-menu .dx-item-content.dx-treeview-item-content > .ibis-parent-selected {
    color: var(--base-color);
}
.ibis-menu-container{
    background-color: var(--menu-container);
}

.ibis-menu-container dx-tree-view {
    padding-top: 5px;
}

.ibis-user-icon {
    margin-top: 2px;
    margin-right: -5px;
    margin-left: 5px;
}

/* popup styling */
.ibis-edit-popup .dx-toolbar {
    border-top: 1px solid #e0e0e0;
}
.ibis-edit-popup-logo {
	float: left;
	flex: 1;
}
.ibis-edit-popup-logo > img {
	width:28px; 
	height: 28px; 
	display:block
}
.ibis-edit-popup-title {
    padding: 10px 10px;
    min-height: 48px;
    background-color: var(--menu-container);
    color: var(--menu-text-color)
}
.ibis-edit-popup-title > h3 {
    flex: 4;
    margin: 5px 6px;
    text-transform: uppercase;
	float: left;
}
.ibis-edit-popup-title dx-button {
	float: right;
}

.dx-popup-bottom.dx-toolbar {
    border-top: 1px solid var(--border-color);
}

.dx-popup-content {
    background-color: #f7f7f7;
}

.dx-popup-content dx-form {
    padding: 10px 15px;
    background-color: white;
    border: 1px solid var(--border-color);
}

/* content styling */
.ibis-content-block {
    margin: 0px;
    /* background-color: #f7f7f7; */
    /* padding: 0px 10px; */
}
.ibis-content {
    flex-grow: 1;
    background-color: #f7f7f7;
    padding: 0px 10px;
}

/* top-right - user info*/
.ibis-user-info{
    border-left: 1px solid var(--border-color);
    display: flex;
    padding: 10px 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
}
.ibis-fullName-text{
    margin: 0 10px;
    color:var(--text-color);
    font-weight: bold;
}
.ibis-role-text{
    font-size: 12px;
    margin: 0 10px;
    color:var(--text-color);
}

/* footer styling */
ibis-footer {
    display: block;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding: 10px;
    color: var(--text-color);
    width: 100%;
}
.ibis-footer-copy {
    width: 50%;
    text-align: right;
    display: inline-block;
}
.ibis-footer-version {
    width: 50%;
    display: inline-block;
}

/* breadcrumb styling */
ibis-breadcrumb {
    display: flex;
    padding: 12px 0px;
    font-weight: bold;
    height: 41px;
	color: var(--text-color);
}
.ibis-breadcrumb-item {
    display: flex;
    font-size: 14px;
    line-height: 1.2857;
}
.ibis-breadcrumb-item p {
    margin: 0px 5px;
}
.ibis-breadcrumb-icon {
    height: 18px;
}
/* ibis-shortcut */
ibis-shortcut {
	text-align: right;
	width: 50%;
}
ibis-shortcut .ibis-shortcut{
    margin-top: 5px;
}
ibis-shortcut .dx-tabs, ibis-shortcut .dx-tab{
	background-color: transparent;
	text-align: right;
}
.ibis-content-block {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--border-color);
}
ibis-shortcut .dx-tabs .dx-tab {
    display: inline-block;
}

/* header styling */
ibis-header .dx-toolbar .dx-toolbar-items-container .dx-toolbar-after {
    padding: 0 20px;
}
ibis-header .ibis-header-item {
    text-align: center;
}
ibis-header .ibis-header-icon {
    width: 30px;
    font-size: 20px;
    color: var(--complement-color);
    cursor: pointer;
}
ibis-header .ibis-header-icon.ibis-user-icon{
    color:var(--base-color);
}
ibis-header .dx-toolbar{
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
ibis-header .rotate-arrow{
    transform: rotate(90deg);
}
.ibis-header-popover .dx-list-item-content{
    padding: 10px;
    color: var(--text-color);
}

/* ibis-header .border-top{
    border-top: 1px solid #777;
  }
  
ibis-header .border-bottom{
    border-bottom: 1px solid rgb(138, 138, 138);
} */
/* .dx-overlay-wrapper.dx-popup-wrapper.dx-overlay-modal.dx-overlay-shader{
    z-index: 1502 !important;
} */

/* LOGIN */
ibis-login #ibis-login-box{
    position: inherit !important;
    width: 220px !important;
    transition: .7s ease-in-out;
    -webkit-transition: .7s ease-in-out;
    margin: 0 auto;
}
ibis-login #ibis-login-box dx-form{
    opacity: 1 !important;
}
ibis-login .ibis-sign-in .dx-button{
    background: var(--base-color);
    margin: 20px 20px 10px;
    width: calc(100% - 40px);
    border: 1px solid var(--base-color);
    color: var(--menu-text-color);
    /* border-radius: 40px; */
}
ibis-login .ibis-sign-in .dx-button-text{
    line-height: 17px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: .04em;
}
ibis-login .ibis-apps-list .dx-item.dx-list-item{
    margin: 5px 0;
    background-color: white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

ibis-login .ibis-apps-list .dx-item.dx-list-item .redirect-link, 
ibis-login .ibis-apps-list .dx-item.dx-list-item .redirect-name {
    margin: 0;
    padding: 0;
}
ibis-login .ibis-list-box{
    margin: 32px auto 0px;
    width: 170px;
}
ibis-login .ibis-list-box .ibis-select-list{
    margin: 15px 0 30px;
}
ibis-login h4, ibis-login h3{
    text-align: center;
    margin:0;
}

.gridContainer {
    /* margin: 10px 10px; */
    min-height: 200px;
    /* border: 1px solid #e7e7e7; */
    /* border-left: 1px solid var(--border-color); */
    /* border-right: 1px solid var(--border-color); */
}

.gridContainer .dx-datagrid-header-panel,
.gridContainer .dx-toolbar {
    background-color: #f7f7f7;
}

.gridContainer .dx-toolbar .dx-toolbar-items-container {
    height: 48px;
}

.globalContainer {
    margin: 10px 10px 0;
    background-color: white;
}
.box-shadow {
    /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); */
    background-color: #fff;
    /* margin: 10px; */
}

.dx-form > .dx-item.dx-box-item{
    height: 37px;
    padding-top: 5px !important;
}

.dx-layout-manager .dx-field-item:not(.dx-first-row) {
    padding-top: 5px !important;
}

.dx-field-item-content:not(.dx-field-item-has-group):not(.dx-field-item-has-tabs) {
    padding-top: 5px !important;
}

.dx-item.dx-toolbar-item.dx-toolbar-label{
    max-width: initial !important;
}

.loginContainer{
    height: calc(100vh - 250px);
}

.registerContainer {
    min-height: calc(100vh - 200px);
}

#finishTransForm .dx-form-group-caption {
    font-size: 17px;
    font-weight: 500;
}

#finishTransForm .dx-form-group-with-caption .dx-form-group-content {
    padding: 0;
}

.TabPanelGrids {
    margin: 10px 10px;
}

.header-box {   
    padding: 8px 10px;
}

.header-item {
    margin: 0px 10px;
    display: inline-block;
}

.header-item:last-of-type {
    height: 29px;
}

.label-item {
    display: inline-block;
    vertical-align: top;
    margin: 10px 5px 0 0;
    font-size: 13px;
}

.item-box{
    display: inline-block;
}

.wizard-button {
    margin: 0 0 25px 0;
}

.num-box{
    width: 150px;
    padding: 5px;
    margin: 5px 5px 5px 10px;
    text-align: center;
    color: white;
    display: inline-block;
    height: 150px;
    vertical-align: top;
    -webkit-box-shadow: 0 7px 11px -2px rgba(0, 0, 0, 0.2); 
    box-shadow: 0 7px 11px -2px rgba(0, 0, 0, 0.2);
}

#analize-container{
    display: flex;
}

.squares {
   display: inline-block;
    width: 100%;
}

.filter-form {
    padding: 8px 25px;
}

.charts {
    width: calc(100% - 5px);
    display: flex;
    padding-bottom: 10px;
}

.generareChart, .transportChart, .elimChart, .valorifChart {
    padding: 10px;
    margin: 50px 10px 0px;
    width: calc(48% - 5px);
    border: 1px solid gray;
}

.analyzesChart {
    padding: 10px;
    margin: 0px 10px;
    width: calc(99% - 7px);
    border: 1px solid gray;
}

.analyzesGrid {
    margin: 10px 10px 0;
    width: calc(99% - 7px);
    border: 1px solid gray;
    height: calc(32vh - 28px);
}

#chart {
    width: calc(100% - 25px);
    margin: 20px 10px 0px;
}

.dashboardList {
    margin: 10px 0;
    border: 1px solid #3333332b;
    width: 290px;
    box-sizing: border-box;
    height: calc( 100% - 20px);
}

.left-box {
    width: calc(100% - 300px);
}

.blue{
    background: rgb(107, 149, 211);
}
.green{
    background-color: #a9d971;
}
.red{
    background-color: #f26f5a;
}
.purple{
    background-color: #a886e0;
}

.left-part{
    width: 30%;
    display: inline-flex;
}

#header .container-custom .left {
    display: none;
}
#header .container-custom .left #logo-img {
    position: relative;
    margin-top: 10px;
    margin-left: 10px;
    display: none;
}

.col {
    margin-top: 70px;
    box-sizing: border-box;
    float: left;
    padding: 0 15px;
    width: 46%;
 }

.dx-datagrid-header-panel .dx-toolbar-before {
    margin-left: 6px;
    font-weight: bold;
    font-size: 14px;
    color: rgb(149, 149, 149);
}

.options {
    position: relative;
    font-weight: bold;
    z-index: 1;
    top:10px;
    line-height: 38px;
    width: 350px;
    color: black;
}

h3 {
    font-weight: 500;
}

.current-company-box{
    margin: 5px 0;
}
.current-company-box h3{
    margin-left: 10px;
}

#actionsColumn div{
    margin: 2px;
}

.custom {
    text-decoration: none !important;
}

/* .dx-success {
    color: #339933 !important;
    font-size: 16px;
}

.dx-danger {
    color: red !important;
    font-size: 16px;
} */
.item a, .sub-item a{
    cursor: pointer;
}

.toolbarImage {
    width: 17px;
    display: inline-block !important;
    height: 17px;
    vertical-align: middle;
    margin-right: 6px;
    margin-top: -5px;
}

.fieldTextbox {
    width: 100px;
}

#mainNavbar .dx-tabs-wrapper {
    display: flex;
    width: 100%;
    min-width: 1110px;
    text-align: left;
    background-color: #b1e3f1;
    height: 100%;
    box-shadow: 2px 2px 2px #afced7;
}

#mainNavbar .dx-tab.dx-tab-selected:last-child:before{
    height: 0px;
}

#mainNavbar .dx-tab:nth-last-child(1), #mainNavbar .dx-tab:nth-last-child(2),
#mainNavbar .dx-tab:nth-last-child(3), #mainNavbar .dx-tab:nth-last-child(4){
    min-width: 36px;
    padding: 9px 0px;
    /* float:right; */
    text-align: right;
}

#mainNavbar .dx-tab:nth-last-child(4) {
    margin-left: auto;
}

#header #mainNavbar .dx-tab.dx-tab-selected:after ,#header .dx-tab.dx-state-focused ,#subheader .dx-tab.dx-tab-selected:after ,#subheader .dx-tab.dx-state-focused{
    border: none;
}

#subheader .dx-tabs-wrapper {
    float: right;
    width: 100%;
    text-align: right;
    height: 35px;
}

#mainNavbar .dx-tabs-wrapper > .dx-tab.dx-item:first-child {
    /* padding: 0;
    margin-left: 15px; */
    font-weight: bold;
}
#mainNavbar .dx-scrollable-horizontal .dx-tab.dx-item:last-child{
    float:none;
}

#header .dx-tabs .dx-tab {
    font-size: 18px;
    /* color: #212529; */
    text-decoration: none;
    vertical-align: middle;
    /* -webkit-transition: all .3s ease-in-out; */
    display: inline-block;
    border: none;
    background-color: transparent;

}

.closeButton {
    right: 10px;
    top: 3px;
    cursor: pointer;
    position: absolute;
}

.closeButton dx-button {
    border:none;
}

.wizardImage img{
    height: 35px;
}

#loginModal{
    position: absolute;
    top: 0;
    background: white;
    width: 100%;
    height: 100%;
    z-index: 9000;
}

.dx-row.dx-column-lines.dx-virtual-row {
    display: none;
}

.dx-row.bold{
    font-weight: bold;
}

.dx-row.stockAlert{
    color: red;
    font-weight: bold;
}

/* .dx-field-item-label-location-top{ 
    padding: 5px 0 3px;
} */
/* .dx-layout-manager .dx-field-item:not(.dx-first-row){
    padding-top: 5px;
} */

/* .dx-popup-title.dx-toolbar .dx-toolbar-items-container {
    height: 56px;
}

.dx-popup-bottom.dx-toolbar .dx-toolbar-items-container {
    height: 42px;
} */

.dx-datagrid-rowsview {
    border-bottom: 1px solid #ddd;
}

.row{
    display: flex;
    flex-wrap: wrap;
    flex: 1;
}

.dx-red{
    color: red;
    font-weight: bold;
}

.dx-green{
    color: green;
    font-weight: bold;
}

.dx-yellow{
    color: #ffca21;
    font-weight: bold;
}

.dx-bold {
    font-weight: bold;
}

.remove-expand{
    cursor: default;
}

.wizardFooter {
    border-top:  1px solid gray;
    width: 97%;
    padding-top: 10px;
    position: absolute;
    bottom: 10px; 
    margin: 5px 0 10px 0;
}

.wizardFooter dx-button[icon=chevronright] {
    float: right;
}

ul.wizard, ul.wizard li {
    margin: 2px 0 0;
    padding: 0;
  }
  ul.wizard {
    counter-reset: num;
  }
  ul.wizard li {
    list-style-type: none;
    float: left;
    position: relative;
    text-align: center;
  }
  ul.wizard li::before {
    display: block;
    counter-increment: num;
    content: counter(num);
    width: 1.5em;
    height: 1.5em;
    text-align: center;
    line-height: 1.5em;
    border-radius: 50%;
    background: #9fcf68;
    margin: 0 auto;
  }
  ul.wizard li ~ li::after {
    content: '';
    position: absolute;
    width: 100%;
    right: 50%;
    height: 4px;
    background-color: #9fcf68;
    top: calc(.75em - 2px);
    z-index: -1;
  }
  ul.wizard li.active::before {
    background: #317928;
    color: white;
  }
  ul.wizard .active ~ li::before,
  ul.wizard .active ~ li::after {
    background: #b7b7b7;
  }
  ul.wizard span {
    display: inline-block;
    color: #7d7d7d;
    font-size: 12px;
    font-family: Arial;
  }

  .wizardHeader {
    height: 45px !important;
  }

  @media (max-width: 880px) {
    #mainNavbar .dx-tabs-wrapper > .dx-tab.dx-item:first-child img {
        height: 50px;
        margin-top: -18px;
    }
}

@media (max-width: 945px) {

    .generareChart, .transportChart, .elimChart, .valorifChart {
        padding: 10px;
        margin: 50px 10px 0px;
        width: calc(45% - 5px);
        border: 1px solid gray;
    }

    .num-box{
        width: 125px;
        padding: 5px;
        margin: 5px 5px 5px 10px;
        text-align: center;
        color: white;
        display: inline-block;
        height: 150px;
        vertical-align: top;
        -webkit-box-shadow: 0 7px 11px -2px rgba(0, 0, 0, 0.2); 
        box-shadow: 0 7px 11px -2px rgba(0, 0, 0, 0.2);
    }

    .header-item {
        width: 190px;
        height: 50px;
    }

    div.header-item:last-child {
        margin-top: 5px;
    }
}

@media (max-width: 750px) {

    .num-box{
        width: 105px;
        padding: 5px;
        margin: 5px 5px 5px 10px;
        text-align: center;
        color: white;
        display: inline-block;
        height: 150px;
        vertical-align: top;
        -webkit-box-shadow: 0 7px 11px -2px rgba(0, 0, 0, 0.2); 
        box-shadow: 0 7px 11px -2px rgba(0, 0, 0, 0.2);
    }

    .charts {
        display: block;
        margin-bottom: 50px;
    }
    .generareChart, .transportChart, .elimChart, .valorifChart {
        padding: 10px;
        margin: 50px 10px 0px;
        width: calc(95% - 8px);
        border: 1px solid gray;
    }

    #chart {
        width: calc(100% - 25px);
        margin: 20px 10px 0px;
    }

}

.company-symbol{
    background-color:#e5634e;
    color:white;
    font-weight: 700;
    padding:10px;
    display: inline-block;
}
.company-name{
    display: inline-block;
}
.company-symbol-list{
    background-color:#b1e3f1;
    padding:5px;
    font-weight: 600;
}
.submenu-list{
    font-size: 16px;
}
.submenu-list-company{
    font-size: 14px;
}
.submenu-list .dx-list-item, .submenu-list-company .dx-list-item{
    border-top:none;
}

a.info-tab{
    position: static;
    display: inline;
    opacity: 1;
    background-color: inherit;
}
.first-st{
    margin-block-end: 0;
    margin-block-start: 0;
    margin: 8px 9px;
}
.second-st{
    margin-block-end: 0;
    margin-block-start: 0;
}

/* .content-block {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 20px;
} */

.dx-field-item-help-text {
    font-size: 10px;
    font-style: italic;
}

/* .dx-datagrid-export-button .dx-icon-export-excel-button:before {
    content: url(assets/img/export.svg);
  }

  .dx-datagrid-export-button {
      border: none;
  } */

  .report-form-container {
        width: 600px;
        margin: 0 auto;
        padding: 10px;
        height: calc(100vh - 143px);
  }
  
  #report-form{
      margin: 50% 0;
  }

  .report-footer {
      margin-top: 10px;
      text-align: center;
  }

  .recheck {
      vertical-align: middle;
      color: red;
  }

  .theme-switcher {
      margin-left: 10px;
  }
  
   .header-toolbar.dx-toolbar .dx-toolbar-items-container {
    height: calc( 40px * var(--isCompact) + 55px * (1 - var(--isCompact)));
  }

  .iconUrl-support {
    height: calc( 14px * var(--isCompact) + 24px * (1 - var(--isCompact)));
    vertical-align: middle;
    filter: invert(calc(.54 * var(--isCompact) + .45 * (1 - var(--isCompact))));
  }

  /* .gridContainer .dx-icon.dx-icon-column-chooser {
    color: #333;
  } */

  .account-body .dx-switch-wrapper {
      top: 0px;
  }

  /* .generateForm .dx-item-content.dx-box-item-content .dx-first-row.dx-field-item.dx-col-7 {
      padding: 0;
      margin-top: 20px;
  } */

  .custom-text-box{
      width: 100%;
      /* width: calc(100% - 40px); */
      display:inline-block;
  }

  .custom-add{
      position: absolute;
      border-style: none;
      right: 48px;
      vertical-align: initial;
  }

  .TabPanelGrids td.dx-command-edit.dx-command-edit-with-icons { 
    display: flex;
  }

  .rotate {
    animation: rotation 3s infinite linear;
  }
  .logo-loading{
    position: fixed;
    top: calc(50vh - 50px);
    right: calc(50vw - 50px);
  }
  .loading-modal{
    width: 100vw;
    height: 100vh;
  }
  
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }



/*************************** MOBILE RESPONSIVE **************************************/
@media only screen and (max-width: 768px) {


.dx-datagrid-header-panel .dx-toolbar-menu-container .dx-button{
    margin-left: 0px;
}

.dx-datagrid-search-panel.dx-textbox.dx-texteditor.dx-editor-underlined.dx-searchbox.dx-show-clear-button.dx-texteditor-empty.dx-widget{
    width: 90px!important;
    overflow: hidden;
}

.dx-datagrid-search-panel.dx-textbox.dx-texteditor.dx-editor-underlined.dx-searchbox.dx-show-clear-button.dx-widget{
    width: 90px!important;
}

.dx-datagrid, .dx-gridbase-container, .generareChart{
    zoom: 0.8;
}

.dx-overlay-content.dx-popup-normal.dx-resizable{
    min-width: 90vw!important;
    transform: translate(10px, 20px)!important;
}

.dx-popup-content, .dx-toolbar.dx-widget.dx-visibility-change-handler.dx-collection.dx-popup-title{
    zoom: 0.9;
    max-height: calc(80vh - 201px)!important;
}

.dx-popup-bottom.dx-toolbar .dx-toolbar-items-container{
    height: 76px;
}

.dx-popup-bottom.dx-toolbar .dx-toolbar-item{
    display: table-row;
}

.dx-popup-title.dx-toolbar .dx-toolbar-items-container {
    height: 50px;
}

.dx-datagrid-header-panel{
    padding-left: 5px;
    padding-right: 5px;
}

.dx-datagrid-group-panel .dx-group-panel-message {
    white-space: normal;
    width: 70px;
    height: 60px;
    font-size: 8px;
}

.globalContainer{
    overflow: scroll;
}

#company-options{
    height: 12px!important;
    width: 12px!important;
    padding: 12px!important;
    border-radius: 10px!important;
    background: url(/assets/img/company.png);
    background-size: contain;
}

#company-options p{
    display: none;
}

#company-options p:after{
    content: "Co";
}

.report-footer .dx-button{
    margin-bottom: 10px;
}

.accountContainer{
    height: fit-content;
}

.account-body{
    width: 90%!important;
    padding-top: 10px!important;
}

.user-symbol{
    height: 80px!important;
    width: 80px!important;
}

.symbol-text{
    line-height: 0.8;
}
}

.error{
    font-weight: bold;
    color: #f44336;
}
.warning{
	font-weight: bold;
    color: #ff5722;
}
.info{
	font-weight: bold;
    color: #8bc34a;
}
/****************************** END MOBILE ******************************************/